<template>
  <div>
    <v-data-table :items="animals" :headers="headers">
      <template v-slot:[`item.externalId`]="{ item }">
        <span class="dress-as-link" @click="showDetails(item)">{{ item.externalId }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon :disabled="item.percentageSold != 100" large
          @click.stop.prevent="sendForSlaugtherDialog(item)">mdi-send-clock-outline</v-icon>
        <v-icon disabled large>
          mdi-align-horizontal-distribute
        </v-icon>
      </template>
      <template v-slot:[`item.percentageSold`]="{ item }">
        {{ item.percentageSold }} %
      </template>
    </v-data-table>

    <v-dialog v-model="showSendForSlaugther">
      <v-card>
        <v-card-title>Send animal to slaugther</v-card-title>
        <v-card-text>
          Do you want to send this animal to slaugther?
        </v-card-text>
        <v-card-text>
          {{ animalSelected.externalId }} of type {{ animalSelected.type }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn data-cy="closeHarvestAnimalDialog" color="blue darken-1" text
            @click="showSendForSlaugther = false">Cancel</v-btn>
          <v-btn data-cy="confirmReadyForHarvest" color="primary" dark class="mb-2"
            @click="confirmSendForSlaugther">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsDialog">
      <v-card>
        <v-card-title>Pre-Order Details</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th> Quantity </th>
                  <th> Name </th>
                  <th> Price </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in carcass.meatPreOrders" :key="item.meatPieceId">
                  <td>{{ item.quantity }}</td>
                  <td>{{ carcass.availableMeatPieces[item.meatPieceId].name }}</td>
                  <td>{{ formattedCurreny(item.customerPriceInCents) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn data-cy="closeDetailsDialog" color="blue darken-1" text @click="showDetailsDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ul>
      <li>See how sales are going</li>
      <li>Enable send to slaugther button once all packages have been sold</li>
      <li>Enter details about retrieved meat vacuum bags and handover to shipping</li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'ButcherInventory',
  components: {},

  data: () => ({
    animals: [],
    headers: [
      { text: 'ID', align: 'start', value: 'externalId' },
      { text: 'Type', align: 'start', value: 'type' },
      { text: 'Actions', value: 'actions', sortable: false },
      { text: 'Sold', value: 'percentageSold', sortable: true }
    ],
    animalSelected: {},
    carcass: {},
    showSendForSlaugther: false,
    showDetailsDialog: false
  }),

  beforeMount: function () {
    this.loadAnimals()
  },

  methods: {
    formattedCurreny (value) {
      return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'EUR' }).format(value / 100)
    },

    loadAnimals () {
      fetch('/api/animals/sale-ready', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((responseAnimals) => responseAnimals.json())
        .then((dataAnimals) => {
          const animals = dataAnimals.animals

          animals.forEach((animal) => {
            animal.percentageSold = 0 // so that all have a valid value

            fetch(`/api/butchershop/pre-orders/${animal.animalId}`, {
              headers: {
                Authorization: 'Bearer ' + `${this.$keycloak.token}`
              }
            })
              .then((response) => response.json())
              .then((data) => {
                animal.percentageSold = data.percentageSold
              })

            this.animals = animals
          })
        })
    },

    sendForSlaugtherDialog (item) {
      this.animalSelected = item
      this.showSendForSlaugther = true
    },

    confirmSendForSlaugther () {
      // TODO API call that will create a TASK
      this.showSendForSlaugther = false
      this.animalSelected = {}
    },

    showDetails (item) {
      fetch(`/api/butchershop/pre-orders/${item.animalId}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((preOrder) => {
          this.carcass = preOrder
        })
      this.showDetailsDialog = true
    }
  }
}
</script>

<style scoped>
.dress-as-link {
  text-decoration: underline;
}
</style>
